import { Close } from "@mui/icons-material";
import { img_lib } from "../utils/utils";
import { useState } from "react";
import {
    useMediaQuery,
    useTheme,
    Dialog,
    DialogContent,
    IconButton,
    ImageList,
    ImageListItem,
    Stack,
    Typography
} from "@mui/material";

export const Gallery = () => {
    const theme = useTheme();
    const smallScreen = useMediaQuery(_ => theme.breakpoints.down('md'));

    const [selectedImage, setSelectedImage] = useState<string>("");

    const handleImageClick = (imageSrc: string) => setSelectedImage(imageSrc);
    const handleClose = () => setSelectedImage("");

    return (
        <Stack id="gallery" spacing={2} alignItems="center" sx={{ px: 2 }}>
            <Dialog open={Boolean(selectedImage)} onClose={handleClose}>
                <DialogContent>
                    <Stack spacing={2}>
                        <IconButton
                            aria-label="close"
                            onClick={handleClose}
                            size="small"
                            sx={{ position: 'absolute', right: 4, top: 4 }}
                        >
                            <Close />
                        </IconButton>
                        {selectedImage && <img src={selectedImage} alt="Enlarged view" style={{ width: '100%' }} />}
                    </Stack>
                </DialogContent>
            </Dialog>
            <Typography variant="h4">Galerie</Typography>
            <ImageList sx={{ width: smallScreen ? "80%" : "60%" }} gap={10} cols={smallScreen ? 2 : 3}>
                {img_lib.map(({src, alt}) => (
                    <ImageListItem key={src}>
                        <img
                            src={`${src}?w=164&h=164&fit=crop&auto=format`}
                            srcSet={`${src}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                            loading="lazy"
                            alt={alt}
                            onClick={() => handleImageClick(src)}
                            style={{ cursor: "pointer" }}
                        />
                    </ImageListItem>
                ))}
            </ImageList>
        </Stack>
    );
};