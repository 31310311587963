import { Stack, Typography } from "@mui/material";
import { LocalPhoneOutlined, MailOutlined } from "@mui/icons-material";

export const Contact = () => {
    return (
        <Stack id="contact" spacing={2} alignItems="center">
            <Typography variant="h4">Kontakt</Typography>
            <Stack direction="row" spacing={1}>
                <MailOutlined fontSize="small"/>
                <Typography fontWeight="bold">ma.sestak@seznam.cz</Typography>
            </Stack>
            <Stack direction="row" spacing={1}>
                <LocalPhoneOutlined fontSize="small"/>
                <Typography fontWeight="bold">+420 739 524 501</Typography>
            </Stack>
        </Stack>
    )
}