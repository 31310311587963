export const img_lib = [
    {
        src: "img/fronthouse.jpg",
        alt: "patro_pudorys",
    },
    {
        src: "img/IMG_8015.heic",
        alt: "photo",
    },
    {
        src: "img/IMG_8340.heic",
        alt: "photo",
    },
    {
        src: "img/IMG_8385.heic",
        alt: "photo",
    },
    {
        src: "img/IMG_7887.jpg",
        alt: "photo",
    },
    {
        src: "img/IMG_7919.heic",
        alt: "photo",
    },
];
