import { Link, Stack, Typography } from "@mui/material";

export const Navbar = () => {
    return (
        <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ borderBottom: 1, borderColor: "lightgrey", position: "sticky", top: 0, backgroundColor: "white", zIndex: 1 }}
            p={3}
        >
            <Typography variant="h6">
                Horský dům Klínovec
            </Typography>

            <Stack direction="row" spacing={2}>
                <Link href="#gallery" underline="hover" sx={{ color: "black" }}>
                    <Typography>galerie</Typography>
                </Link>
                <Link href="#contact" underline="hover" sx={{ color: "black" }}>
                    <Typography>kontakt</Typography>
                </Link>
            </Stack>
        </Stack>
    )
}