import { Close } from "@mui/icons-material";
import { useState } from "react";
import {
    useMediaQuery,
    useTheme,
    Dialog,
    DialogContent,
    IconButton,
    Stack
} from "@mui/material";

export const Gallery2 = () =>{
    const theme = useTheme();
    const smallScreen = useMediaQuery(_ => theme.breakpoints.down('md'));

    const [selectedImage, setSelectedImage] = useState<string>("");

    const handleImageClick = (imageSrc: string) => setSelectedImage(imageSrc);
    const handleClose = () => setSelectedImage("");

    return (
        <>
            <Dialog open={Boolean(selectedImage)} onClose={handleClose}>
                <DialogContent>
                    <Stack spacing={2}>
                        <IconButton
                            aria-label="close"
                            onClick={handleClose}
                            size="small"
                            sx={{ position: 'absolute', right: 4, top: 4 }}
                        >
                            <Close />
                        </IconButton>
                        {selectedImage && <img src={selectedImage} alt="Enlarged view" style={{ width: '100%' }} />}
                    </Stack>
                </DialogContent>
            </Dialog>
            <Stack direction="row" alignItems="center" justifyContent="center">
                <img
                    src="img/prizemi_pudorys.png"
                    width={smallScreen ? "45%" : "30%"}
                    alt="prizemi_pudorys"
                    onClick={() => handleImageClick("img/prizemi_pudorys.png")}
                    style={{ cursor: "pointer" }}
                />
                <img
                    src="img/patro_pudorys.png"
                    width={smallScreen ? "45%" : "30%"}
                    alt="patro_pudorys"
                    onClick={() => handleImageClick("img/patro_pudorys.png")}
                    style={{ cursor: "pointer" }}
                />
            </Stack>
        </>
    )
}